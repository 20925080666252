import React from 'react';
import css from './SearchSection.module.css';
import { Button, Box, Divider, TextField, Autocomplete } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import { useState, useEffect } from 'react';
import { inputStyles } from '../../../util/inputStyles';
import { RegNumInput } from '../RegNumInput';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

export function SearchSection() {
  const [formValues, setFormValues] = useState({
    selectedBrand: '',
    selectedModel: {},
    selectedYear: {},
    selectedSerie: '',
  });

  const [isFocused, setIsFocused] = useState({
    selectedBrand: false,
    selectedModel: false,
    selectedYear: false,
    selectedSerie: false,
  });

  const [dataState, setDataState] = useState({
    fetchedBrands: { data: [], fetch: false },
    fetchedModels: { data: [], fetch: false },
    fetchedYears: { data: [], fetch: false },
    fetchedSeries: { data: [], fetch: false },
    tireInfo: { data: [], fetch: false },
  });
  const history = useHistory();
  const [disabled, setDisabled] = useState(true);
  const dispatch = useDispatch();
  const api = process.env.REACT_APP_API_URL;
  const [listingType, setListingType] = React.useState('');
  const [inputsDisabled, setInputsDisabled] = useState(true);
  const [savedRegNum, changeRegNum] = useState("")

  const handleChange = event => {
    setListingType(event.target.value);
    setInputsDisabled(false);
  };

  useEffect(() => {
    if (dataState.fetchedBrands.fetch) {
      fetch(`${api}/api/proxy/wheelsize/makes`)
        .then(response => response.json())
        .then(data => {
          const filteredBrands = data.filter(brand => brand.regions.includes('eudm'));
          const formattedData = filteredBrands.map(selectedBrand => ({
            slug: selectedBrand.slug || '',
            name_en: selectedBrand.name_en || '',
            label: selectedBrand.name_en || '',
            logo: selectedBrand.logo,
            regions: selectedBrand.regions || [],
          }));

          setDataState(prev => ({
            ...prev,
            fetchedBrands: { data: formattedData, fetch: false },
          }));
        })
        .catch(error => console.error('Error fetching brands:', error));
    }
  }, [dataState.fetchedBrands.fetch]);

  useEffect(() => {
    if (dataState.fetchedModels.fetch) {
      if (formValues.selectedBrand && formValues.selectedBrand.slug) {
        const fetchModels = async () => {
          try {
            const response = await fetch(
              `${api}/api/proxy/wheelsize/models?make=${formValues.selectedBrand.slug}`
            );
            if (!response.ok) throw new Error(`Failed to fetch models: ${response.statusText}`);
            const data = await response.json();
            const formattedData = data.map(selectedModel => ({
              slug: selectedModel.slug || '',
              name_en: selectedModel.name_en || '',
              label: selectedModel.name_en || '',
            }));
            setDataState(prev => ({
              ...prev,
              fetchedModels: { data: formattedData, fetch: true },
            }));
          } catch (error) {
            console.error('Error fetching models:', error);
            setDataState(prev => ({
              ...prev,
              fetchedModels: { data: [], fetch: true },
            }));
          }
        };

        fetchModels();
      } else {
        setDataState(prev => ({
          ...prev,
          fetchedModels: { data: [], fetch: true },
        }));
      }
    }
  }, [dataState.fetchedModels.fetch]);

  useEffect(() => {
    if (dataState.fetchedYears.fetch) {
      if (formValues.selectedModel && formValues.selectedModel.slug) {
        const fetchYears = async () => {
          try {
            const response = await fetch(
              `${api}/api/proxy/wheelsize/years?make=${formValues.selectedBrand.slug}&model=${formValues.selectedModel.slug}`
            );
            if (!response.ok) throw new Error(`Failed to fetch years: ${response.statusText}`);
            const data = await response.json();
            const formattedData = data.map(selectedYear => ({
              slug: selectedYear.slug || '',
              name: selectedYear.name || '',
              label: selectedYear.name || '',
            }));
            setDataState(prev => ({
              ...prev,
              fetchedYears: { data: formattedData, fetch: true },
            }));
          } catch (error) {
            console.error('Error fetching years:', error);
            setDataState(prev => ({
              ...prev,
              fetchedYears: { data: [], fetch: true },
            }));
          }
        };

        fetchYears();
      } else {
        setDataState(prev => ({
          ...prev,
          fetchedYears: { data: [], fetch: true },
        }));
      }
    }
  }, [dataState.fetchedYears.fetch]);

  useEffect(() => {
    if (dataState.fetchedSeries.fetch) {
      if (formValues.selectedYear && formValues.selectedYear.slug) {
        const fetchSeries = async () => {
          try {
            const response = await fetch(
              `${api}/api/proxy/wheelsize/series?make=${formValues.selectedBrand.slug}&model=${formValues.selectedModel.slug}&year=${formValues.selectedYear.slug}`
            );
            if (!response.ok) throw new Error(`Failed to fetch series: ${response.statusText}`);
            const data = await response.json();
            const formattedData = data.map(selectedSeries => ({
              slug: selectedSeries.slug || '',
              name: selectedSeries.name || '',
              label: selectedSeries.name || '',
            }));
            setDataState(prev => ({
              ...prev,
              fetchedSeries: { data: formattedData, fetch: true },
            }));
          } catch (error) {
            console.error('Error fetching series:', error);
            setDataState(prev => ({
              ...prev,
              fetchedSeries: { data: [], fetch: true },
            }));
          }
        };

        fetchSeries();
        setDisabled(false);
      } else {
        setDataState(prev => ({
          ...prev,
          fetchedSeries: { data: [], fetch: true },
        }));
      }
    }
  }, [dataState.fetchedSeries.fetch]);

  useEffect(() => {
    const fetchTireInfo = async () => {
      if (dataState.tireInfo.fetch) {
        if (formValues.selectedSerie && formValues.selectedSerie.slug) {
          try {
            const response = await fetch(
              `${api}/api/proxy/wheelsize/tireinfo?make=${formValues.selectedBrand.slug}&model=${formValues.selectedModel.slug}&year=${formValues.selectedYear.slug}&modification=${formValues.selectedSerie.slug}`
            );
            if (!response.ok) throw new Error(`Failed to fetch tire info: ${response.statusText}`);
            const data = await response.json();

            handleRedirect(data);
          } catch (error) {
            console.error('Error fetching tire info:', error);
            setDataState(prev => ({
              ...prev,
              tireInfo: { ...prev.tireInfo, data: [], fetch: false },
            }));
          }
        }
      }
    };

    fetchTireInfo();
  }, [dataState.tireInfo.fetch]);

  const handleFocus = field => () => {
    setIsFocused(prev => ({ ...prev, [field]: true }));

    let stateKey = '';
    switch (field) {
      case 'selectedBrand':
        stateKey = 'fetchedBrands';
        break;
      case 'selectedModel':
        stateKey = 'fetchedModels';
        break;
      case 'selectedYear':
        stateKey = 'fetchedYears';
        break;
      case 'selectedSerie':
        stateKey = 'fetchedSeries';
        break;
      default:
        return;
    }

    if (!dataState[stateKey].fetch) {
      setDataState(prev => ({
        ...prev,
        [stateKey]: { ...prev[stateKey], fetch: true },
      }));
    }
  };

  const handleBlur = field => event => {
    setIsFocused({ ...isFocused, [field]: false });
  };

  function extractTireMeasurements(str) {
    const strReplaced = str.replace(/[a-zA-Z/]/g, '');
    const tireWidth = strReplaced.slice(0, 3);
    const profile = strReplaced.slice(3, 5);
    const diameter = strReplaced.slice(5, 7);
    return { tireWidth, profile, diameter };
  }

  const filterEmptyEntries = valuesArray => valuesArray.filter(value => value !== '');

  function extractValues(tires, prop) {
    const values = tires.map(tire => tire[prop]);

    const newSet = [...new Set([...values])];
    const newValues = filterEmptyEntries(newSet);

    return newValues.join('%2C');
  }

  function extractBothValues(front, rear, prop) {
    const frontValues = front.map(tire => (tire ? tire[prop] : ''));
    const rearValues = rear.map(tire => (tire ? tire[prop] : ''));

    const newSet = [...new Set([...frontValues, ...rearValues])];
    const newValues = filterEmptyEntries(newSet);

    return newValues.join('%2C');
  }

  const handleRedirect = fetchedData => {
    const car = fetchedData.data[0];
    // const frontWheels = [];
    // const rearWheels = [];

    // car.wheels.map(set => {
    //   frontWheels.push(set.front);
    //   rearWheels.push(set.rear);
    // });

    // const frontTires = frontWheels?.map(wheel => {
    //   return extractTireMeasurements(wheel.tire);
    // });

    // const rearTires = rearWheels?.map(wheel => {
    //   return extractTireMeasurements(wheel.tire);
    // });

    // let filterParams = {};
    // let listingTypeKey = 0;

    // if (listingType === 'tire') {
    //   filterParams = {
    //     profileFront: !extractValues(frontTires, 'profile')
    //       ? ''
    //       : `pub_profileFront=${extractValues(frontTires, 'profile')}`,
    //     profileRear: !extractValues(rearTires, 'profile')
    //       ? ''
    //       : `pub_profileRear=${extractValues(rearTires, 'profile')}`,
    //     tireWidthFront: !extractValues(frontTires, 'tireWidth')
    //       ? ''
    //       : `pub_tireWidthFront=${extractValues(frontTires, 'tireWidth')}`,
    //     tireWidthRear: !extractValues(rearTires, 'tireWidth')
    //       ? ''
    //       : `pub_tireWidthRear=${extractValues(rearTires, 'tireWidth')}`,
    //     diameter: !extractBothValues(frontTires, rearTires, 'diameter')
    //       ? ''
    //       : `pub_diameter=${extractBothValues(frontTires, rearTires, 'diameter')}`,
    //   };
    //   listingTypeKey = 1;
    // } else if (listingType === 'rim') {
    //   filterParams = {
    //     diameter: !extractBothValues(frontTires, rearTires, 'diameter')
    //       ? ''
    //       : `pub_diameter=${extractBothValues(frontTires, rearTires, 'diameter')}`,
    //     numberOfBolts: !car.technical.stud_holes
    //       ? ''
    //       : `pub_numberOfBolts=${car.technical.stud_holes}`,
    //     boltCircle: !car.technical.pcd ? '' : `pub_boltCircle=${car.technical.pcd}`,
    //     hubHole: !car.technical.centre_bore ? '' : `pub_hubHole=${car.technical.centre_bore}`,
    //   };
    //   listingTypeKey = 2;
    // } else {
    //   filterParams = {
    //     profileFront: !extractValues(frontTires, 'profile')
    //       ? ''
    //       : `pub_profileFront=${extractValues(frontTires, 'profile')}`,
    //     profileRear: !extractValues(rearTires, 'profile')
    //       ? ''
    //       : `pub_profileRear=${extractValues(rearTires, 'profile')}`,
    //     tireWidthFront: !extractValues(frontTires, 'tireWidth')
    //       ? ''
    //       : `pub_tireWidthFront=${extractValues(frontTires, 'tireWidth')}`,
    //     tireWidthRear: !extractValues(rearTires, 'tireWidth')
    //       ? ''
    //       : `pub_tireWidthRear=${extractValues(rearTires, 'tireWidth')}`,
    //     diameter: !extractBothValues(frontTires, rearTires, 'diameter')
    //       ? ''
    //       : `pub_diameter=${extractBothValues(frontTires, rearTires, 'diameter')}`,
    //     numberOfBolts: !car.technical.stud_holes
    //       ? ''
    //       : `pub_numberOfBolts=${car.technical.stud_holes}`,
    //     boltCircle: !car.technical.pcd ? '' : `pub_boltCircle=${car.technical.pcd}`,
    //     hubHole: !car.technical.centre_bore ? '' : `pub_hubHole=${car.technical.centre_bore}`,
    //   };
    // }

    const filterParams = {
      numberOfBolts: !car.technical.stud_holes
        ? ''
        : `pub_numberOfBolts=${car.technical.stud_holes}`,
      boltCircle: !car.technical.pcd ? '' : `pub_boltCircle=${car.technical.pcd}`,
      carBrandCAtegory: !car.make.name ? '' : `pub_categoryLevel1=${car.make.name.toLowerCase()}`,
    };

    // //Some characters must be replaced due to the config in Sharetribe Console
    const filtersUrl = Object.values(filterParams)
      .join('&')
      .replace(/\./g, '_')
      .replace(/-/g, 'n');

    history.push(`annonser?${filtersUrl}`);

    let listingTypeKey = 0;

    if (listingType === 'tire') {
      listingTypeKey = 1;
    } else if (listingType === 'rim') {
      listingTypeKey = 2;
    }

    dispatch({
      type: 'SET_CAR',
      payload: car,
    });
    dispatch({
      type: 'SET_LISTINGTYPE',
      payload: listingTypeKey,
    });
  };

  const handleClick = () => {
    if (!dataState.tireInfo.fetch) {
      setDataState(prev => ({
        ...prev,
        tireInfo: { ...prev.tireInfo, fetch: true, loading: true },
      }));
    }
  };

  return (
    <Box
      className={css.searchSectionContainer}
      padding="16px"
      backgroundColor="var(--wheelplaceWhite)"
      borderRadius="10px"
    >
      {/* Commented out for the time being */}

      {/* <FormControl sx={{ marginBottom: '16px' }} fullWidth>
        <InputLabel id="demo-simple-select-label">Välj annonstyp</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={listingType}
          label="Annonstyp"
          onChange={handleChange}
        >
          <MenuItem value="wheel">Hjul</MenuItem>
          <MenuItem value="tire">Däck</MenuItem>
          <MenuItem value="rim">Fälg</MenuItem>
        </Select>
      </FormControl> */}

      <RegNumInput
        page="searchPage"
        listingType={listingType}
        savedRegNum={savedRegNum}
        changeRegNum={changeRegNum}
      />

      <Divider
        sx={{
          margin: '1rem',
          justifyContent: 'center',
          fontSize: '18px',
        }}
      >
        eller
      </Divider>

      <Box component="section" backgroundColor="var(--wheelplaceWhite)">
        <Autocomplete
          id="combo-box-demo"
          options={dataState.fetchedBrands.data}
          getOptionLabel={option => option.name_en || ''}
          isOptionEqualToValue={(option, value) => option.name_en === value.name_en}
          style={{ width: '100%' }}
          sx={{
            ...inputStyles,
            marginBottom: '16px',
          }}
          renderInput={params => (
            <TextField
              {...params}
              label="Märke"
              variant="outlined"
              placeholder="Välj ett märke"
              InputProps={{
                ...params.InputProps,

                startAdornment: (
                  <>
                    {formValues.selectedBrand && (
                      <img
                        src={formValues.selectedBrand.logo}
                        alt={formValues.selectedBrand.name_en}
                        style={{
                          marginRight: '10px',
                          width: '35px',
                          height: '24px',
                        }}
                      />
                    )}
                    {params.InputProps.startAdornment}
                  </>
                ),
              }}
              InputLabelProps={{
                ...params.InputLabelProps,
                shrink: params.inputProps.value !== '',
              }}
            />
          )}
          renderOption={(props, option) => (
            <Box component="li" {...props} style={{ fontWeight: 'normal' }}>
              <img
                src={option.logo}
                alt={option.name_en}
                style={{
                  marginRight: '10px',
                  width: '35px',
                  height: '24px',
                }}
              />
              {option.name_en}
            </Box>
          )}
          onChange={(event, newValue) => {
            setFormValues({ ...formValues, selectedBrand: newValue || null, selectedModel: '' });
          }}
          onFocus={handleFocus('selectedBrand')}
        />
        <div>
          <TextField
            sx={{ ...inputStyles, marginBottom: '16px' }}
            className={css.searchBox}
            id="outlined-select-model"
            select
            label="Modell"
            value={formValues.selectedModel.slug || ''}
            onChange={event => {
              const selectedModel = dataState.fetchedModels.data.find(
                model => model.slug === event.target.value
              );
              setFormValues(prev => ({
                ...prev,
                selectedModel: selectedModel || {},
              }));
            }}
            onFocus={handleFocus('selectedModel')}
            variant="outlined"
            fullWidth
          >
            {dataState.fetchedModels.data.map(model => (
              <MenuItem key={model.slug} value={model.slug}>
                {model.name_en}
              </MenuItem>
            ))}
          </TextField>
        </div>
        <div>
          <TextField
            sx={{ ...inputStyles, marginBottom: '16px' }}
            className={css.searchBox}
            id="outlined-select-year"
            select
            label="Årsmodell"
            value={formValues.selectedYear.slug ? formValues.selectedYear.slug : ''}
            onChange={event => {
              const yearValue = dataState.fetchedYears.data.find(
                year => year.slug === event.target.value
              );
              setFormValues(prev => ({
                ...prev,
                selectedYear: yearValue || {},
              }));
            }}
            onFocus={handleFocus('selectedYear')}
            variant="outlined"
            fullWidth
          >
            {dataState.fetchedYears.data.map(year => (
              <MenuItem key={year.slug} value={year.slug}>
                {year.name}
              </MenuItem>
            ))}
          </TextField>
        </div>
        <div>
          <TextField
            sx={{
              ...inputStyles,
              marginBottom: '16px',
            }}
            className={css.searchBox}
            id="outlined-select-serie"
            select
            label="Serie"
            value={formValues.selectedSerie.slug ? formValues.selectedSerie.slug : ''}
            onChange={event => {
              const serieValue = dataState.fetchedSeries.data.find(
                serie => serie.slug === event.target.value
              );
              setFormValues(prev => ({
                ...prev,
                selectedSerie: serieValue || {},
              }));
            }}
            onFocus={handleFocus('selectedSerie')}
            onBlur={handleBlur('selectedSerie')}
            variant="outlined"
            fullWidth
          >
            {dataState.fetchedSeries.data.map(serie => (
              <MenuItem key={serie.slug} value={serie.slug}>
                {serie.name}
              </MenuItem>
            ))}
          </TextField>
        </div>

        <Button
          variant="outlined"
          sx={{
            fontWeight: 400,
            fontSize: '18px',
            color: '#707070',
            borderColor: '#BCBCBC',
            transition: 'none',
            textTransform: 'none',
            '&:hover': {
              borderColor: '#202020',
              backgroundColor: 'transparent',
            },
            '@media (max-width: 768px)': {
              fontSize: '16px',
            },
          }}
          aria-label="Search"
          className="search-box"
          onClick={handleClick}
          disabled={disabled}
        >
          Sök
        </Button>
      </Box>
    </Box>
  );
}
