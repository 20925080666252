import React, { useState } from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { Box, Divider } from '@mui/material';
import '../../styles/customMediaQueries.css';
import css from './CarInfoCard.module.css';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useHistory } from 'react-router-dom';

export function CarInfoCard(props) {
  const [activeIndex, setActiveIndex] = useState(null);
  const history = useHistory();

  const center_bore_from_api = `${props?.data?.result?.tire_info?.center_bore ?? ''}`;
  const center_bore = center_bore_from_api.replace('.', '_');
  const model_year = `${props?.data?.result?.model_year ?? ''}`;

  const handleWheelClick = (str, boltCircle, numOfBolts, index, brand, model) => {
    const pattern = /(\d+)\/(\d+)(Z?R)(\d+)/;
    const match = str.match(pattern);

    if (match) {
      let urlParams = {};

      if (props.currentTab === 0 || props.currentTab === 1) {
        const formattedTitle = `${brand ?? ''} ${model ?? ''} ${model_year} ${parseInt(match[1], 10)}`;
        const fields = {
          pub_tireWidthFront: `${parseInt(match[1], 10)}`,
          pub_profileFront: `${parseInt(match[2], 10)}`,
          pub_diameter: `${parseInt(match[4], 10)}`,
          pub_numberOfBolts: `${numOfBolts ?? ''}`,
          pub_boltCircle: `${boltCircle ?? ''}`,
          pub_hubHole: `${center_bore ?? ''}`,
          pub_adType: 'wheel',
          title: formattedTitle,
        };

        props.formApi?.batch(() => {
          Object.entries(fields).forEach(([key, value]) => {
            props.formApi.change(key, value);
          });
        });

        urlParams = {
          tireWidthFront: `pub_tireWidthFront=${fields.pub_tireWidthFront}`,
          profileFront: `pub_profileFront=${fields.pub_profileFront}`,
          diameter: `pub_diameter=${fields.pub_diameter}`,
          numOfBolts: `pub_numberOfBolts=${fields.pub_numberOfBolts}`,
          boltCircle: `pub_boltCircle=${fields.pub_boltCircle}`,
          carBrandCategory: `pub_categoryLevel1=${brand?.toLowerCase() ?? ''}`,
          carModelCategory: `pub_categoryLevel2=${model?.toLowerCase()?.replace(/\s+/g, '') ?? ''}`,
          productType: `pub_adType=${fields.pub_adType}`,
        };
      } else {
        urlParams = {
          diameter: `pub_diameter=${parseInt(match[4], 10)}`,
        };
      }

      const filtersUrl = Object.values(urlParams)
        .join('&')
        .replace(/\./g, '_')
        .replace(/-/g, 'n');

      if (props.page === 'search') {
        history.push({
          search: `?${filtersUrl}`,
        });
      }
    } else {
      return { tireWidth: '', profile: '', diameter: '' };
    }
    setActiveIndex(activeIndex === index ? null : index);
  };

  const handleTireClick = (str, index, brand, model) => {
    const pattern = /(\d+)\/(\d+)(Z?R)(\d+)/;
    const match = str.match(pattern);

    if (match) {
      const formattedTitle = `${brand ?? ''} ${model ?? ''} ${model_year} ${parseInt(match[1], 10)}`;
      let urlParams = {};

      if (props.currentTab === 0 || props.currentTab === 1) {
        const fields = {
          pub_tireWidthFront: `${parseInt(match[1], 10)}`,
          pub_profileFront: `${parseInt(match[2], 10)}`,
          pub_diameter: `${parseInt(match[4], 10)}`,
          pub_hubHole: `${center_bore ?? ''}`,
          pub_adType: 'tire',
          title: formattedTitle,
        };

        props.formApi?.batch(() => {
          Object.entries(fields).forEach(([key, value]) => {
            props.formApi.change(key, value);
          });
        });

        urlParams = {
          tireWidthFront: `pub_tireWidthFront=${fields.pub_tireWidthFront}`,
          // tireWidthRear: `pub_tireWidthRear=${parseInt(match[1], 10)}`,
          profileFront: `pub_profileFront=${fields.pub_profileFront}`,
          // profileRear: `pub_profileRear=${parseInt(match[2], 10)}`,
          diameter: `pub_diameter=${fields.pub_diameter}`,
          // Commented the following two params out because we don't want to show the brand or category
          // carBrandCategory: `pub_categoryLevel1=${brand.toLowerCase()}`,
          // carModelCategory: `pub_categoryLevel2=${model.toLowerCase().replace(/\s+/g, '')}`,
          productType: `pub_adType=${fields.pub_adType}`,
        };
      } else {
        urlParams = {
          diameter: `pub_diameter=${parseInt(match[4], 10)}`,
        };
      }

      const filtersUrl = Object.values(urlParams)
        .join('&')
        .replace(/\./g, '_')
        .replace(/-/g, 'n');

      if (props.page === 'search') {
        history.push({
          search: `?${filtersUrl}`,
        });
      }
    } else {
      return { tireWidth: '', profile: '', diameter: '' };
    }
    setActiveIndex(activeIndex === index ? null : index);
  };

  const handleRimClick = (str, boltCircle, numOfBolts, index, brand, model) => {
    const match = str.match(/x(\d{2})/);
    const diameter = match ? match[1] : null;

    const fields = {
      pub_numberOfBolts: `${numOfBolts ?? ''}`,
      pub_boltCircle: `${boltCircle ?? ''}`,
      pub_diameter: `${diameter ?? ''}`,
      pub_hubHole: `${center_bore ?? ''}`,
      pub_adType: 'rim',
    };

    props.formApi?.batch(() => {
      Object.entries(fields).forEach(([key, value]) => {
        props.formApi.change(key, value);
      });
    });

    let urlParams = {
      numOfBolts: `pub_numberOfBolts=${fields.pub_numberOfBolts}`,
      boltCircle: `pub_boltCircle=${fields.pub_boltCircle}`,
      diameter: `pub_diameter=${fields.pub_diameter}`,
      carBrandCategory: `pub_categoryLevel1=${brand?.toLowerCase() ?? ''}`,
      carModelCategory: `pub_categoryLevel2=${model?.toLowerCase()?.replace(/\s+/g, '') ?? ''}`,
      productType: `pub_adType=${fields.pub_adType}`,
    };

    const filtersUrl = Object.values(urlParams)
      .join('&')
      .replace(/\./g, '_')
      .replace(/-/g, 'n');

    if (props.page === 'search') {
      history.push({
        search: `?${filtersUrl}`,
      });
    }
    setActiveIndex(activeIndex === index ? null : index);
  };

  const List = props => {
    return (
      <Box
        component={'div'}
        sx={{
          padding: '0px 0px 10px 20px',
        }}
      >
        <h3
          style={{
            fontWeight: 'var(--fontWeightMedium)',
            fontSize: '21px',
            lineHeight: '33.6px',
            margin: '0px',
          }}
        >
          {props.type === 'wheel' ? <p>Hjul</p> : props.type === 'tire' ? <p>Däck</p> : <p>Fälg</p>}
        </h3>

        {props.listItems.data.map(entry => {
          return entry.data.map((data, index) => (
            <p
              onClick={
                props.type === 'wheel'
                  ? () =>
                      handleWheelClick(
                        data,
                        props.boltCircle,
                        props.numOfBolts,
                        index,
                        props.brand,
                        props.model
                      )
                  : props.type === 'tire'
                  ? () => handleTireClick(data, index, props.brand, props.model)
                  : () =>
                      handleRimClick(
                        data,
                        props.boltCircle,
                        props.numOfBolts,
                        index,
                        props.brand,
                        props.model
                      )
              }
              key={index}
              className={css.arrayListItem}
            >
              {data.replaceAll(".00", "")}
            </p>
          ));
        })}
      </Box>
    );
  };

  let data;
  let car = {};
  let rim = {};
  let tire = {};
  let wheel = {};

  if (props.formType === 'licensePlate') {
    car = props.data.result;
    let frontTires = [];
    let rearTires = [];
    let frontRims = [];
    let rearRims = [];

    car.tire_info.tires.map(tire => {
      frontTires.push(tire.front.tire);
      rearTires.push(tire.rear.tire);
      frontRims.push(tire.front.rim);
      rearRims.push(tire.rear.rim);
    });

    const allFrontTires = [...new Set([...frontTires, car.original_tires.front_tires])];
    const allRearTires = [...new Set([...rearTires, car.original_tires.rear_tires])];
    const allFrontRims = [...new Set([...frontRims, car.original_tires.front_rims])];
    const allRearRims = [...new Set([...rearRims, car.original_tires.rear_rims])];
    const allTires = [...new Set([...allFrontTires, ...allRearTires])];
    const allRims = [...new Set([...allFrontRims, ...allRearRims])];

    rim = {
      data: [{ title: 'Fälg', data: allRims }],
    };
    tire = {
      data: [{ title: 'Däck', data: allTires }],
    };
    wheel = {
      data: [{ title: 'Hjul', data: allTires }],
    };
  } else {
    car = props.data.car;
    let frontTires = [];
    let rearTires = [];
    let frontRims = [];
    let rearRims = [];

    car.wheels.map(tire => {
      frontTires.push(tire.front.tire);
      rearTires.push(tire.rear.tire);
      frontRims.push(tire.front.rim);
      rearRims.push(tire.rear.rim);
    });

    //For some reason rear wheel data is missing from wheelsize api therefore the removal of empty entries is necessary
    const allTires = [...new Set([...frontTires, ...rearTires])].filter(tire => tire !== '');
    const allRims = [...new Set([...frontRims, ...rearRims])].filter(tire => tire !== '');

    rim = {
      data: [{ title: 'Fälg', data: allRims }],
    };
    tire = {
      data: [{ title: 'Däck', data: allTires }],
    };
    wheel = {
      data: [{ title: 'Hjul', data: allTires }],
    };
  }

  if (props.listingType === 'rim') {
    data = rim;
  } else if (props.listingType === 'tire') {
    data = tire;
  }

  const {
    bolts,
    bolt_circle,
  } = props?.data?.result?.tire_info || {};

  const frontRims = props?.data?.result?.original_tires?.front_rims || "";
  const et = frontRims.includes('ET') ? frontRims.split("ET")[1] : frontRims.includes('x') ? frontRims.split("x")[2] : "";

  return (
    <Box
      component="section"
      width="100%"
      backgroundColor="white"
      borderRadius="10px"
      sx={{
        marginBottom: '20px',
        '@media (min-width: 430px)': {
          marginTop: '10px',
        },
      }}
    >
      <Accordion defaultExpanded={true}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel-content"
          id="panel-header"
        >
          <h2 className={css.carDetailHeading}>
            {props.formType === 'licensePlate' ? (
              <>
                {car.brand} {car.sales_name} {car.model_year}
              </>
            ) : (
              <>
                {car.make.name} {car.model.name} {car.name}
              </>
            )}
          </h2>
        </AccordionSummary>
        <Box
          sx={{ padding: '16px' }}
        >
          <div className={css.extraSpecTitle}>
            <FormattedMessage id="CarInfoCard.extraSpecTitle" />
          </div>
          <div className={css.extraSpecContent}>
            {bolts ? <div className={css.extraSpecItem}>
              {bolts} bolts
            </div> : null}
            {bolt_circle ? <div className={css.extraSpecItem}>
              <b><FormattedMessage id="CarInfoCard.extraSpec.boltPattern" /></b>
              {bolt_circle} mm
            </div> : null}
            {center_bore_from_api ? <div className={css.extraSpecItem}>
              <b><FormattedMessage id="CarInfoCard.extraSpec.centerBore" /></b>
              {center_bore_from_api} mm
            </div> : null}
            {et ? <div className={css.extraSpecItem}>
              <b><FormattedMessage id="CarInfoCard.extraSpec.et" /></b>
              {et} mm
            </div> : null}
          </div>
        </Box>
        <AccordionDetails>
          <Divider
            sx={{
              marginBottom: '10px',
              '@media (min-width: 767px)': {
                maxWidth: '40%',
              },
            }}
          />
          <Box
            sx={{
              '@media (min-width: 767px)': {
                display: 'grid',
                gridTemplateColumns: 'repeat(3, 1fr)',
              },
            }}
          >
            {props.listingType === 'tire' || props.listingType === 'rim' ? (
              <List listItems={data} brand={car.brand} type={props.listingType} />
            ) : (
              <>
                <List
                  listItems={wheel}
                  type="wheel"
                  page={props.page}
                  brand={car.brand ? car.brand : car.make.name}
                  model={car.model.name ? car.model.name : car.model}
                  boltCircle={
                    car.tire_info?.bolt_circle ? car.tire_info.bolt_circle : car.technical ? car.technical.pcd : ''
                  }
                  numOfBolts={car.tire_info?.bolts ? car.tire_info.bolts : car.technical ? car.technical.stud_holes : ''}
                />
                <List
                  listItems={tire}
                  type="tire"
                  page={props.page}
                  brand={car.brand ? car.brand : car.make.name}
                  model={car.model.name ? car.model.name : car.model}
                />
                <List
                  listItems={rim}
                  type="rim"
                  page={props.page}
                  brand={car.brand ? car.brand : car.make.name}
                  model={car.model.name ? car.model.name : car.model}
                  boltCircle={
                    car.tire_info?.bolt_circle ? car.tire_info.bolt_circle : car.technical ? car.technical.pcd : ''
                  }
                  numOfBolts={car.tire_info?.bolts ? car.tire_info.bolts : car.technical ? car.technical.stud_holes : ''}
                />
              </>
            )}
          </Box>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
}
